<template>

<div class="body">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style>

.body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
}

</style>
